<div class="dashboard">
    <h2 class="font-weight-400">{{ title }}</h2>
    <div class="dashboard__grid" [style.margin-top.px]="20" [@slideInCards] *ngIf="this?.apps?.length > 0; else noApps">
        <sphere-app-card data-pendo="appTile" *ngFor="let app of apps" [app]="app"></sphere-app-card>
    </div>
</div>

<ng-template #noApps>
    <p class="no-apps-message">We're sorry, you are not authorized to use any application. Please contact your system administrator.</p>
</ng-template>
