import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthorizationServiceRESTRoleService, ReadPolicy } from '../authorization/generated';

@Injectable({ providedIn: 'root' })
export class PolicyDataService extends DefaultDataService<ReadPolicy> {
    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private policyApi: AuthorizationServiceRESTRoleService) {
        super('ReadPolicy', http, httpUrlGenerator);
    }

    getWithQuery(queryParams: QueryParams | string): Observable<ReadPolicy[]> {
        // Get all policies by company and role
        if (queryParams['companyCode'] && queryParams['roleId']) {
            return this.policyApi.getRolePolicies(queryParams['companyCode'], parseInt(queryParams['roleId']));
        }

        // Get assigned policies
        if (queryParams['companyCode'] && queryParams['username']) {
            return this.policyApi.getPolicies(queryParams['companyCode'], queryParams['username']);
        }
    }
}
