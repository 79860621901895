import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from './keycloak/keycloak.service';
import { from, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard {
    constructor(protected router: Router, protected keycloak: KeycloakService) {}

    public canActivateChild(): Observable<boolean> {
        return from(this.keycloak.isLoggedIn()).pipe(
            catchError(error => of(false)),
            tap(isLoggedIn => {
                if (!isLoggedIn) {
                    this.keycloak.login();
                }
            })
        );
    }
}
