import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/router';
import { NavigationService } from '@app/portal/navigation/navigation.service';
import { KeycloakService } from '@app/security/keycloak/keycloak.service';
import { AppConfigService } from '@app/service/app-config.service';

@Component({
    selector: 'sphere-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: [
        trigger('slideInCards', [
            transition(':enter', [
                query(
                    '.plugin-card',
                    [
                        style({ transform: 'translateY(100%)', opacity: 0 }),
                        stagger('35ms', [animate('180ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ transform: 'translateY(0%)', opacity: 1 }))])
                    ],
                    { optional: true }
                )
            ])
        ])
    ]
})
export class DashboardComponent implements OnInit {
    title = 'PLACEHOLDER';
    apps: Route[];

    constructor(
        private appConfig: AppConfigService,
        private keycloak: KeycloakService,
        private nav: NavigationService
    ) {}

    ngOnInit() {
        this.title = this.appConfig.dashboardTitle;
        this.apps = this.keycloak.getUserApps(this.nav.apps);
    }
}
